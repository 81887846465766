<template>
  <div class="mem-view events-list">
    <div class="mem-title">{{ $t("upcoming_events") }}</div>

    <div class="promo-section" v-if="!loading && showPromoSection">
      <promo-card-he />
    </div>

    <empty-list v-if="!loading && eventsList.length === 0">
      <template v-slot:default>
        {{ $t("no_events") }}
      </template>
      <template v-slot:footer>
        <span class="empty-description">
          {{ $t("empty_event_description") }}
        </span>
      </template>
    </empty-list>

    <div class="list-loading" v-if="loading">
      <div class="loading-line">
        <div class="animated"></div>
      </div>
      <div class="loading-line"></div>
      <div class="loading-line">
        <div class="animated"></div>
      </div>
    </div>
    <!-- Events -->
    <div class="list-wrap" v-if="!loading && eventsList.length !== 0">
      <event-card
        v-for="event in eventsList"
        :key="event.id"
        :event="event"
        :start-date="event.starts"
        :pic="event.image_small"
      />
    </div>
    <!-- Past Events -->
    <div class="past-events">
      <mem-button
        class="past-events-button"
        v-if="showPastEventsButton"
        btn-type="tertiary-dark"
        @click="loadPastEvents"
      >
        {{ $t("show_past_events") }}
      </mem-button>
      <div v-if="showPastEvents">
        <div class="mem-title">{{ $t("past_events") }}</div>

        <div class="list-loading" v-if="pastEventsLoading">
          <div class="loading-line">
            <div class="animated"></div>
          </div>
          <div class="loading-line"></div>
          <div class="loading-line">
            <div class="animated"></div>
          </div>
        </div>
        <event-card
          v-else
          v-for="event in pastEventsList"
          :key="event.id"
          :event="event"
          :start-date="event.starts"
          :pic="event.image_small"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EventCard from "../components/EventCard.vue";
import EmptyList from "../components/EmptyList.vue";
import { mapActions, mapGetters } from "vuex";
import localizeResolver from "@/common/mixins/localizeResolver";

export default {
  name: "EventsList",
  mixins: [localizeResolver],
  data: () => ({
    loading: true,
    pastEventsLoading: false,
    showPastEvents: false,
  }),
  components: {
    EventCard,
    EmptyList,
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "promo-card-he": () => import("@/components/banners/BannerHotelEngine.vue"),
  },
  computed: {
    ...mapGetters([
      "userData",
      "eventsList",
      "hasPastEvents",
      "specificLinks",
      "pastEventsList",
    ]),
    showPastEventsButton() {
      return !this.showPastEvents && this.hasPastEvents;
    },
    showPromoSection() {
      if (this.userData.spartan_country_code !== "US") return false;
      return this.eventsList.length === 0;
    },
  },
  methods: {
    ...mapActions(["getEventsList", "getPastEventsList"]),
    async loadPastEvents() {
      this.showPastEvents = true;
      this.pastEventsLoading = true;
      try {
        await this.getPastEventsList();
      } catch (error) {
        console.log(error);
      }
      this.pastEventsLoading = false;
    },
  },
  async mounted() {
    try {
      await this.getEventsList();
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.events-list {
  max-width: 100vw;
  overflow-x: hidden;
  .promo-section {
    margin-bottom: 38px;
    max-width: 670px;
  }
  .mem-title {
    @include elementMarginBottom("M");
  }

  .list-wrap {
    @include elementMarginBottom("M");
  }

  .past-events {
    display: flex;
    flex-direction: column;
    max-width: min(670px, 100%);
    padding: 0;
    margin: 0;

    .past-events-button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.list-loading {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  overflow: hidden;
  max-width: 670px;
  @include elementMarginBottom("M");

  .loading-line {
    height: 136px;
    background-color: #020000;
    position: relative;
    overflow: hidden;

    .animated {
      -webkit-animation: loading-line 2s infinite;
      animation: loading-line 2s infinite;
      content: " ";
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      // -webkit-transform: translateX(-100%);
      // transform: translateX(-100%);
      background: linear-gradient(
        110deg,
        rgba(0, 0, 0, 0),
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.15) 70%,
        rgba(255, 255, 255, 0.05),
        rgba(0, 0, 0, 0)
      );
    }
  }
}

@media screen and (max-width: 499px) {
  .list-loading {
    .loading-line {
      height: 156px;
    }
  }
}
</style>
